#weekly-table[data-v-38f7d7] {
  margin-left: auto;
  margin-right: auto;
}

#weekly-table td.monospace[data-v-38f7d7] {
  padding-left: 5px;
  padding-right: 5px;
  font-family: monospace;
}

#weekly-table th[data-v-38f7d7] {
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
}

#weekly-table th[data-v-38f7d7]:hover {
  background-color: #007d0080;
}

.form-button-container {
  justify-content: space-between;
  display: flex;
}

#feedbackTextArea {
  color: #fff;
  background: #fff3;
  border: none;
  border-radius: 5px;
}

#feedbackTextArea:focus {
  outline: none;
}

#feedbackTextArea::placeholder {
  color: #f5f5f5;
}

.cancel {
  color: #000;
  background-color: tomato;
  border: none;
  border-radius: 5px;
}

.submit {
  color: #000;
  background-color: #98fb98;
  border: none;
  border-radius: 5px;
}

.form-button-container button {
  cursor: pointer;
  width: calc(50% - 5px);
}

#feedback-container {
  width: 300px;
  height: 200px;
  z-index: 1000;
  background-color: #f0fc;
  position: fixed;
  top: 0;
  left: 0;
}

#feedback-container.collapsed {
  width: 300px;
  height: 100px;
  animation: wiggle 10s ease-in-out infinite;
}

#feedback-container p {
  color: #000;
  text-align: center;
  cursor: pointer;
  padding: 15px;
  font-size: 13px;
  font-weight: bold;
}

#feedback-container textarea {
  width: 100%;
  height: 100%;
}

@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(10deg);
  }

  20% {
    transform: rotate(0deg);
  }

  80% {
    transform: rotate(0deg);
  }

  90% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

p[data-v-10a405] {
  color: #000;
}

/*# sourceMappingURL=index.21dc30cb.css.map */
